import React from "react";
import Layout from "../layouts/page";
import TalkCard from "../components/talk-card";
import data from "../../data/talks.json";

const { talks } = data;

export default function Speaking() {
  const talkItems = talks.map((talk, i) => {
    return (
      <TalkCard
        key={"talk-" + i}
        youtubeId={talk.youtubeId}
        date={talk.date}
        title={talk.title}
        event={talk.event}
        abstract={talk.abstract}
        slidesId={talk.slidesId}
        otherEvents={talk.additional_events}
        url={talk.url}
      />
    );
  });

  return (
    <Layout path="/speaking">
      <div className="row">
        <div className="col-12 col-md-6 p-5">
          <img src="/speaking.png" className="img-header" alt="speaking" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 px-5">{talkItems}</div>
      </div>
    </Layout>
  );
}
