import React from "react";
import youtubeUrl from "../helpers/youtube-url";
import List from "./helpers/list";
import Youtube from "./helpers/youtube";

export default function TalkCard({
  slidesId,
  youtubeId,
  event,
  otherEvents,
  title,
  abstract,
  url,
}) {
  const hasSlides = !!slidesId;
  const slidesUrl = "https://slides.com/danielleadams/" + slidesId;

  return (
    <div className="post-card container-fluid mb-5">
      <div className="row px-3 py-4">
        <div className="col-md-4 d-flex align-items-center">
          <div className="width-100">
            {youtubeId ? (
              <Youtube
                youtubeId={youtubeId}
                classNames="card-img-top"
                height="250"
              />
            ) : (
              <a href={url} target="_blank" rel="noreferrer">
                <img
                  src="/podcast.png"
                  className="img-fluid"
                  alt="Podcast episode"
                />
              </a>
            )}
          </div>
        </div>
        <div className="col">
          <div className="card-body">
            <h5 className="card-text muted">{event}</h5>
            <h4 className="card-title">
              {youtubeId ? (
                <a
                  href={youtubeUrl(youtubeId)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {title}
                </a>
              ) : (
                <a href={url} target="_blank" rel="noreferrer">
                  {title}
                </a>
              )}
            </h4>
            <p>{abstract}</p>
            {hasSlides ? (
              <p className="card-text">
                <a href={slidesUrl}>Slides</a>
              </p>
            ) : (
              ""
            )}
            <List
              title="Also Given At:"
              items={otherEvents}
              classNames="card-text"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
